// ScrollAnimation.js

import React from 'react';
import { useInView } from 'react-intersection-observer';
import '../css/scrollAnimation.css'; // Create a CSS file for your animation styles

const ScrollAnimation = ({ children }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <div
      ref={ref}
      className={`scroll-animation ${inView ? 'fade-in' : ''}`}
    >
      {children}
    </div>
  );
};

export default ScrollAnimation;
