import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapSigns, faMobile, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import logo from '../images/logo2.jpeg';
import '../css/footerStyle.css';
import ScrollAnimation from './ScrollAnimation';

const Footer = () => {
  return (
    <>
      <ScrollAnimation>
        <div className="bg-gray-800 font-footer text-white py-8 animate-fade-in">
          <div className="container mx-auto">
            <div className="grid grid-cols-1 lg:grid-cols-4">
              <div className="text-center mb-8">
                <img src={logo} alt="Logo" className="w-48 mx-auto lg:ml-4" />
              </div>
              <div className="col-span-3 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                <div>
                  <h3 className="text-lg font-bold mb-4 text-center">Pakistan Head Office</h3>
                  <div className='flex items-center'>
                    <div className="ml-4">
                      <div className="flex items-center mb-2">
                        <FontAwesomeIcon icon={faMapSigns} className="mr-2" />
                        <p>A-223, Railway Cooperative Housing Society, Block No.13/A, Gulshan-e-Iqbal, Karachi</p>
                      </div>

                      <div className="flex items-center mb-2">
                        <FontAwesomeIcon icon={faMobile} className="mr-2" />
                        <p>
                          <a href="https://wa.me/923258965625" target="_blank" rel="noopener noreferrer" className="hover:text-orange-600">+92325-8965625</a>
                        </p>
                      </div>

                      <div className="flex items-center hover:text-orange-600">
                        <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                        <p><a href="mailto:haniyatradingcoltd@gmail.com">haniyatradingcoltd@gmail.com</a></p>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <h3 className="text-lg font-bold mb-4 text-center">Mauritius Office</h3>
                  <div className='flex items-center'>
                    <div className="ml-4">
                      <div className="flex items-center mb-2">
                        <FontAwesomeIcon icon={faMapSigns} className="mr-2" />
                        <p>45, Marcelin Savrimootoo Str Port-Louis</p>
                      </div>

                      <div className="flex items-center mb-2">
                        <FontAwesomeIcon icon={faMobile} className="mr-2" />
                        <p>
                          <a href="https://wa.me/23054919147" target="_blank" rel="noopener noreferrer" className="hover:text-orange-600">+230-54919147</a>, 
                          <a href="https://wa.me/2302859519" target="_blank" rel="noopener noreferrer" className="hover:text-orange-600">+230-2859519</a>, 
                          <a href="https://wa.me/23057779016" target="_blank" rel="noopener noreferrer" className="hover:text-orange-600">+230-5777 9016</a>
                        </p>
                      </div>

                      <div className="flex items-center hover:text-orange-600">
                        <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                        <p><a href="mailto:haniyatradingcoltd@gmail.com">haniyatradingcoltd@gmail.com</a></p>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <h3 className="text-lg font-bold mb-4 text-center">Australia Office</h3>
                  <div className='flex items-center'>
                    <div className="ml-4">
                      <div className="flex items-center mb-2">
                        <FontAwesomeIcon icon={faMapSigns} className="mr-2" />
                        <p>140 Pound Rd Hampton Park VIC 3976, Melbourne</p>
                      </div>

                      <div className="flex items-center mb-2">
                        <FontAwesomeIcon icon={faMobile} className="mr-2" />
                        <p>
                          <a href="https://wa.me/61412862418" target="_blank" rel="noopener noreferrer" className="hover:text-orange-600">+61 412 862 418</a>
                        </p>
                      </div>

                      <div className="flex items-center hover:text-orange-600">
                        <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                        <p><a href="mailto:haniyatradingcoltd@gmail.com">haniyatradingcoltd@gmail.com</a></p>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </ScrollAnimation>
    </>
  )
}

export default Footer;
