// Preloader.js

import React from 'react';
import '../css/preloader.css';

const Preloader = () => {
  return (
    <div className="preloader">
      <div className="loader">
      <div className="left-to-right"></div>
        <div className="right-to-left"></div>
      </div>
    </div>
  );
};

export default Preloader;