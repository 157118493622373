import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import img1 from '../images/logo2.jpeg';
import logo from '../images/logo2.jpeg';

const Navbar = () => {
  useEffect(() => {
    document.title = 'Haniya Trading Website';
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 5) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav
      className={`fixed left-0 right-0 w-full z-50 font-sans font-bold transition ease-in-out duration-300 ${
        scrolled ? 'bg-gray-800 shadow-lg' : 'bg-transparent'
      }`}
    >
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="flex-shrink-0 flex items-center">
              <img src={logo} alt="Logo" className="h-[4vh]" />
            </div>
          </div>
          <div className="flex items-center md:hidden">
            <button
              onClick={toggleNavbar}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
            >
              <svg
                className={`h-[3vh] w-[3vh] ${isOpen ? 'hidden' : 'block'}`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
              <svg
                className={`h-[3vh] w-[3vh] ${isOpen ? 'block' : 'hidden'}`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className={`hidden md:flex md:items-center space-x-[2vw] ${isOpen ? 'block' : 'hidden'}`}>
            <NavLink
              to="/"
              className="text-white hover:bg-gray-700 hover:text-orange-700 px-[1vw] py-[0.5vh] rounded-md text-sm font-medium transition duration-300"
            >
              Home
            </NavLink>
            <NavLink
              to="/our-products"
              className="text-white hover:bg-gray-700 hover:text-orange-700 px-[1vw] py-[0.5vh] rounded-md text-sm font-medium transition duration-300"
            >
              Our Products
            </NavLink>
            <NavLink
              to="/aboutus"
              className="text-white hover:bg-gray-700 hover:text-orange-700 px-[1vw] py-[0.5vh] rounded-md text-sm font-medium transition duration-300"
            >
              About Us
            </NavLink>
            <NavLink
              to="/contactus"
              className="text-white hover:bg-gray-700 hover:text-orange-700 px-[1vw] py-[0.5vh] rounded-md text-sm font-medium transition duration-300"
            >
              Contact Us
            </NavLink>
          </div>
        </div>
      </div>
      <div className={`md:hidden ${isOpen ? 'block' : 'hidden'} bg-gray-700 px-[2vw] pt-[2vh] pb-[3vh]`}>
        <NavLink
          to="/"
          className="text-white hover:bg-gray-600 hover:text-orange-700 block px-[3vw] py-[2vh] rounded-md text-base font-medium"
          onClick={() => setIsOpen(false)}
        >
          Home
        </NavLink>
        <NavLink
          to="/our-products"
          className="text-white hover:bg-gray-600 hover:text-orange-700 block px-[3vw] py-[2vh] rounded-md text-base font-medium"
          onClick={() => setIsOpen(false)}
        >
          Our Products
        </NavLink>
        <NavLink
          to="/aboutus"
          className="text-white hover:bg-gray-600 hover:text-orange-700 block px-[3vw] py-[2vh] rounded-md text-base font-medium"
          onClick={() => setIsOpen(false)}
        >
          About Us
        </NavLink>
        <NavLink
          to="/contactus"
          className="text-white hover:bg-gray-600 hover:text-orange-700 block px-[3vw] py-[2vh] rounded-md text-base font-medium"
          onClick={() => setIsOpen(false)}
        >
          Contact Us
        </NavLink>
      </div>
    </nav>
  );
};

export default Navbar;
