import './App.css';
import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Preloader from './components/Preloader';
// Lazy-loaded components
const Home = lazy(() => import('./components/Home'));
const AboutUs = lazy(() => import('./components/AboutUs'));
const ContactUs = lazy(() => import('./components/ContactUs'));
const Gallery = lazy(() => import('./components/Gallery'));
const ErrorPage = lazy(() => import('./components/Error')); // Renamed to ErrorPage to avoid conflicts

function App() {
  return (
    <>
      <Suspense fallback={<div><Preloader /></div>}>
        <Router>
          <Navbar />
          <Routes>

            {/* Ensure each Route has a valid element prop */}
            <Route path="/" element={<Home />} />
            <Route path="/our-products" element={<Gallery />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="*" element={<ErrorPage />} /> {/* Use ErrorPage instead of Error */}

          </Routes>
          <Footer />
        </Router>
      </Suspense >
    </>
  );
}

export default App;
